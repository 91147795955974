/**
 * Created by PanJiaChen on 16/11/18.
 */

/**
 * @param {string} path
 * @returns {Boolean}
 */
export function isExternal (path) {
  return /^(https?:|mailto:|tel:)/.test(path)
}

/**
 * @param {string} str
 * @returns {Boolean}
 */
export function validUsername (str) {
  return valid_map.indexOf(str.trim()) >= 0
}
// 手机号验证
export function regMobile (str) {
  let reg = /^1\d{10}$/
  return reg.test(str)
}
// 座机号验证
export function regTelphone (str) {
  let reg = /^(\(\d{3,4}\)|\d{3,4}-|\s)?\d{7,14}$/
  return reg.test(str)
}
//ip正则表达式
export function isValidIP(ip) {
    var reg = /^(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])\.(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])\.(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])\.(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])$/
    return reg.test(ip);
}
//密码正则表达式 数字 字母 特殊符号
export function isPassword(pwd) {
    var reg = /^(?![0-9]+$)(?![a-z]+$)(?![A-Z]+$)(?!([^(0-9a-zA-Z)])+$)^.{8,16}$/
    return reg.test(pwd);
}
//两位小数
export function twoFloat(val) {
    var reg = /^(([0-9]+)|([0-9]+\.[0-9]{1,2}))$/
    return reg.test(val);
}
//整数
export function init(val) {
    var reg = /^[0-9]+$/
    return reg.test(val);
}
