<template>
    <el-container class="page-container">
        <el-main class="page-main" style="padding: 0 20px">
            <el-form
                    :model="formData"
                    :rules="rules"
                    ref="coupon_form"
                    label-width="140px"
                    class="demo-ruleForm">


                <el-form-item label="优惠券名称" prop="name">
                    <el-row>
                        <el-col :span="6">
                            <el-input size="medium" v-model="formData.name" placeholder="请输入优惠券名称"
                                      :disabled="isDisabled"></el-input>
                        </el-col>
                    </el-row>
                </el-form-item>
                <el-form-item label="类型" prop="type">
                    <el-row class="no_border">
                        <el-col :span="3">
                            <el-radio v-model="formData.type" :label="1" :disabled="isDisabled"
                                      @change="typeChange">现金券
                            </el-radio>
                        </el-col>
                        <el-col :span="5">
                            <el-input size="medium" v-model="formData.minPoint"
                                      :disabled="formData.type !==1 || isDisabled"
                                      @change="typeChange">
                                <template slot="prepend">满</template>
                                <template slot="append">元</template>
                            </el-input>
                        </el-col>
                        <el-col :span="5">
                            <el-input size="medium" v-model="formData.amount"
                                      :disabled="formData.type !== 1 || isDisabled"
                                      @change="typeChange">
                                <template slot="prepend">抵</template>
                                <template slot="append">元</template>
                            </el-input>
                        </el-col>
                    </el-row>
                    <el-row class="no_border">
                        <el-col :span="3">
                            <el-radio v-model="formData.type" :label="2" :disabled="isDisabled"
                                      @change="typeChange">折扣券
                            </el-radio>
                        </el-col>
                        <el-col :span="5">
                            <el-input size="medium" v-model="formData.minPoint"
                                      :disabled="formData.type !== 2 || isDisabled" @change="typeChange">
                                <template slot="prepend">满</template>
                                <template slot="append">元</template>
                            </el-input>
                        </el-col>
                        <el-col :span="5">
                            <el-input size="medium" v-model="formData.scale"
                                      :disabled="formData.type !== 2 || isDisabled"
                                      placeholder="例如：9.5折 输入 95"
                                      @change="typeChange">
                                <template slot="prepend">打</template>
                                <template slot="append">折</template>
                            </el-input>
                        </el-col>
                    </el-row>
                    <el-row class="no_border">
                        <el-col :span="3">
                            <el-radio v-model="formData.type" :label="3" :disabled="isDisabled"
                                      @change="typeChange">满减券
                            </el-radio>
                        </el-col>
                        <el-col :span="5">
                            <el-input size="medium" v-model="formData.minPoint"
                                      :disabled="formData.type !== 3 || isDisabled"
                                      @change="typeChange">
                                <template slot="prepend">满</template>
                                <template slot="append">元</template>
                            </el-input>
                        </el-col>
                        <el-col :span="5">
                            <el-input size="medium" v-model="formData.reduceFee"
                                      :disabled="formData.type !== 3 || isDisabled"
                                      @change="typeChange">
                                <template slot="prepend">减</template>
                                <template slot="append">元</template>
                            </el-input>
                        </el-col>
                    </el-row>
                </el-form-item>
                <el-form-item label="使用时间" prop="coupon_time">
                    <el-row>
                        <el-row class="no_border">

                            <el-col :span="10">
                                <el-date-picker size="medium"
                                                :default-time="['00:00:00', '23:59:59']"
                                                v-model="formData.coupon_time"
                                                type="daterange"
                                                range-separator="至"
                                                start-placeholder="开始日期"
                                                end-placeholder="结束日期">
                                </el-date-picker>
                            </el-col>
                        </el-row>


                    </el-row>
                </el-form-item>
                <el-form-item label="是否限量发放" prop="publishLimit">
                    <el-radio-group v-model="formData.publishLimit" :disabled="isDisabled">
                        <el-radio :label="0">是</el-radio>
                        <el-radio :label="1">否</el-radio>
                    </el-radio-group>
                </el-form-item>


                <el-form-item label="可用范围" prop="useType">
                    <el-select size="medium" v-model="formData.useType" :disabled="isDisabled"
                               placeholder="请选择">
                        <el-option
                                v-for="item in useTypeOptions"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="可用城市" prop="city_id" v-if="formData.useType">
                    <el-select size="medium" v-model="formData.city_id" placeholder="请选择城市" multiple
                               :disabled="isDisabled">
                        <el-option
                                v-for="item in cityArr"
                                :key="item.id"
                                :label="item.label"
                                :value="item.id">
                        </el-option>
                    </el-select>
                </el-form-item>

                <el-form-item label="总发放张数" prop="publishCount">
                    <el-row>
                        <el-col :span="6">
                            <el-input size="medium" v-model="formData.publishCount" placeholder="请输入总发放张数"
                                      :disabled="isDisabled||formData.publishLimit===1"></el-input>
                        </el-col>
                    </el-row>
                </el-form-item>
                <el-form-item label="同一用户最多张数" prop="perLimit">
                    <el-row>
                        <el-col :span="6">
                            <el-input size="medium" v-model="formData.perLimit" placeholder="请输入同一用户最多张数"
                                      :disabled="isDisabled"></el-input>
                        </el-col>
                    </el-row>
                </el-form-item>
                <el-form-item label="已领取张数" prop="receive_count" v-if="$route.query.edit == 2">
                    <el-row>
                        <el-col :span="6">
                            <el-input size="medium" v-model="formData.receive_count" :disabled="isDisabled"></el-input>
                        </el-col>
                    </el-row>
                </el-form-item>
                <el-form-item label="已消费张数" prop="consume_count" v-if="$route.query.edit == 2">
                    <el-row>
                        <el-col :span="6">
                            <el-input size="medium" v-model="formData.consume_count" :disabled="isDisabled"></el-input>
                        </el-col>
                    </el-row>
                </el-form-item>
                <el-form-item label="优惠券状态" prop="couponsStatus"
                              v-if="$route.query.edit == 2 || $route.query.edit ==1">
                    <el-select size="medium" v-model="formData.couponsStatus" placeholder="请选择"
                               :disabled="isDisabled">
                        <el-option
                                v-for="item in statusOptions"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value"
                        >
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="优惠券说明" prop="note">
                    <el-row>
                        <el-col :span="12">
                            <el-input type="textarea" v-model="formData.note" placeholder="优惠券说明"
                                      style="width: 100%" rows="4"
                                      :disabled="isDisabled"></el-input>
                        </el-col>
                    </el-row>
                </el-form-item>
                <p style="textAlign: center;" v-if="isDisabled == false">
                    <el-button size="medium" @click="cancel">取消</el-button>
                    <el-button type="primary" size="medium" @click="submitForm" :disabled="isSubmit">保存
                    </el-button>
                </p>
            </el-form>
        </el-main>
    </el-container>
</template>
<script>

    import {ticketDetail, addTicket, editTicket} from '../../api/ticketManage';
    import {twoFloat, init} from '../../utils/validate';

    const useTypeOptions = [
        {
            label: '全场通用',
            value: 0
        },
        // {
        //     label: '指定分类',
        //     value: 1
        // },
        // {
        //     label: '指定商品',
        //     value: 2
        // },
    ]
    const statusOptions = [
        {
            label: '未上架',
            value: -1
        },
        {
            label: '上架',
            value: 1
        },
        {
            label: '过期',
            value: 2
        },
        {
            label: '下架',
            value: 4
        },
        {
            label: '已领完',
            value: 3
        }
    ];
    export default {
        data() {
            let validateTotalCount = (rule, value, callback) => {
                if (this.formData.publishLimit === 1) {
                    callback();
                } else {
                    if (!value) {
                        callback(new Error('总发放张数不能为空!'));
                    } else if (!/^[1-9]\d*?$/.test(value)) {
                        callback(new Error('只能输入整数!'));
                    } else {
                        callback();
                    }
                }
            };
            //优惠券类型
            let validateCouponType = (rule, value, callback) => {
                switch (value) {
                    case 1:
                        if (!this.formData.minPoint || !this.formData.amount) {
                            callback(new Error('请输入现金券配置'));
                        } else if (!twoFloat(this.formData.minPoint)) {
                            callback(new Error('最多只能输入两位小数'));
                        } else if (!twoFloat(this.formData.amount)) {
                            callback(new Error('最多只能输入两位小数'));
                        } else {
                            callback();
                        }
                        break;
                    case 2:
                        if (!this.formData.minPoint || !this.formData.scale) {
                            callback(new Error('请输入折扣配置'));
                        } else if (!twoFloat(this.formData.minPoint)) {
                            callback(new Error('最多只能输入两位小数'));
                        } else if (!/^([1-9][0-9]{0,1}|100)$/.test(this.formData.scale)) {
                            callback(new Error('请输入1-100数字'));
                        } else {
                            callback();
                        }
                        break;
                    case 3:
                        if (!this.formData.minPoint || !this.formData.reduceFee) {
                            callback(new Error('请输入满减配置'));
                        } else if (!twoFloat(this.formData.minPoint)) {
                            callback(new Error('最多只能输入两位小数'));
                        } else if (!twoFloat(this.formData.reduceFee)) {
                            callback(new Error('最多只能输入两位小数'));
                        } else {
                            callback();
                        }
                        break;
                }
            };
            return {
                isSubmit: false,
                car_module: [],
                coupon_id: null,
                cityArr: [], // 城市
                options: [
                    { // 状态
                        value: 0,
                        label: '未上架',
                        disabled: false
                    }, {
                        value: 1,
                        label: '派发中',
                        disabled: false
                    }, {
                        value: 2,
                        label: '下架',
                        disabled: false
                    }],
                formData: {
                    publishLimit: 0, // 是否无限发放优惠券 1.不限量 2. 限量
                    coupon_time: null, // 获取时间区间字段
                    name: '', // 优惠券名称
                    type: 1, // 优惠券类型 1 ：现金 2  折扣 3  满减
                    minPoint: null, // 最少满多少使用现金券
                    amount: null, // 优惠固定金额


                    reduceFee: null, // 减去多少金额

                    scale: null, // 折扣值


                    coupon_content: '', // 优惠券内容
                    note: '', // 优惠券描述
                    useType: 0, // 可用范围
                    publishCount: null, // 发放总张数
                    receive_count: 0, // 已领取张数
                    consume_count: 0, // 已消费张数
                    perLimit: null, // 用户最获取数量
                    // expire_duration: null, // 使用天数
                    // coupon_start_time: null, // 优惠券生效时间
                    // coupon_end_time: null, // 优惠券结束时间
                    couponsStatus: -1
                },
                rules: {
                    name: [
                        {required: true, message: '优惠券名称不能为空！', trigger: 'change'}
                    ],
                    type: [
                        {validator: validateCouponType, trigger: 'blur'}
                    ],
                    coupon_time: [
                        {required: true, message: '请选择使用时间！', trigger: 'change'}
                    ],

                    publishCount: [
                        {validator: validateTotalCount, trigger: 'blur'}

                    ],
                    perLimit: [
                        {required: true, message: '同一用户最多张数不能为空！', trigger: 'change'},
                        {pattern: /^[1-9]\d*$/, message: '只能输入整数'}
                    ],
                    couponsStatus: [
                        {required: true, message: '优惠券状态不能为空！', trigger: 'change'}
                    ],
                    note: [
                        {required: true, message: '优惠券说明不能为空！', trigger: 'change'}
                    ],
                    useType: [
                        {required: true, message: '请选择使用范围！', trigger: 'change'}
                    ]
                },
                isDisabled: false,
                editId: null,
                useTypeOptions,
                statusOptions,
                //现金券key
                type1key: ['name', 'type', 'amount', 'perLimit', 'minPoint', 'startTime', 'endTime', 'useType', 'note', 'publishLimit', 'publishCount', 'couponsStatus'],
                type2key: ['name', 'type', 'perLimit', 'minPoint', 'startTime', 'endTime', 'useType', 'note', 'publishLimit', 'publishCount', 'couponsStatus', 'scale'],
                type3key: ['name', 'type', 'perLimit', 'minPoint', 'startTime', 'endTime', 'useType', 'note', 'publishLimit', 'publishCount', 'couponsStatus', 'reduceFee'],
            };
        },

        async mounted() {
            try {
                let query = this.$route.query
                query.edit = +query.edit
                console.log(this.$route.query)

                // this.$route.query.edit    0:创建  1: 编辑 2:查看详情
                if (this.$route.query.edit) {
                    let params = {
                        couponId: this.$route.query.id,
                        type: this.$route.query.type
                    }
                    let {data} = await ticketDetail(params);
                    // let
                    let coupon_time = [new Date(data.startTime), new Date(data.endTime)]
                    let formData = {
                        coupon_time,
                        id: data.id,
                        type: data.type,
                        name: data.name,
                        minPoint: data.minPoint,
                        scale: data.scale,
                        reduceFee: data.reduceFee,
                        amount: data.amount,
                        note: data.note,
                        useType: data.useType,
                        publishCount: data.publishCount,
                        perLimit: data.perLimit,
                        couponsStatus: data.couponsStatus,
                        receiveCount: data.receiveCount,
                        consume_count: data.consume_count,
                        publishLimit: data.publishLimit,
                        fullFee:data.fullFee
                    };

                    this.formData = formData;
                    if (this.$route.query.edit === 2) {
                        this.isDisabled = true;
                    } else {
                        this.isDisabled = false;
                    }
                }
            } catch (e) {
                console.log(e)
            }
        },
        methods: {
            back_car_models(modelsArray) {
                let model = [];
                for (let i in modelsArray) {
                    model.push(modelsArray[i].id);
                }
                return model;
            },
            // 类型改变
            typeChange() {
                if (this.formData.type === 1) {
                    this.formData.scale = '';
                    this.formData.reduceFee = '';
                } else if (this.formData.type === 2) {
                    this.formData.amount = '';
                    this.formData.reduceFee = '';
                } else {
                    this.formData.scale = '';
                    this.formData.amount = '';
                }
            },


            // 取消
            cancel() {
                this.$confirm('确定放弃编辑？', '提示', {
                    distinguishCancelAndClose: true,
                    confirmButtonText: '确定',
                    cancelButtonText: '取消'
                }).then(async () => {
                    this.$router.push({path: '/ticketManage'});
                });
            },

            // 保存
            submitForm(formName) {
                console.log(this.formData)
                this.$refs['coupon_form'].validate(async valid => {
                    console.log(valid)
                    if (valid) {
                        // this.isSubmit = true;
                        if (this.formData.coupon_time && this.formData.coupon_time.length) {
                            this.formData.startTime = this.formData.coupon_time[0].getTime()
                            this.formData.endTime = this.formData.coupon_time[1].getTime()

                        }
                        let formData = {}, keys = [];
                        switch (this.formData.type) {
                            case 1:
                                keys = this.type1key;
                                break;
                            case 2:
                                keys = this.type2key;
                                break;
                            case 3:
                                keys = this.type3key;
                                break;
                        }
                        for (let i in keys) {
                            formData[keys[i]] = this.formData[keys[i]]
                        }
                        formData.fullFee = formData.minPoint
                        console.log('formData', formData)
                        try {
                            if (this.$route.query.edit === 1) { // 编辑数据
                                formData.id = this.formData.id;
                                await editTicket(formData);
                            } else { // 添加数据
                                await addTicket(formData);
                            }
                            this.$message({
                                type: 'success',
                                message: '保存成功!'
                            });
                            this.isSubmit = false;
                            this.$router.push({path: '/ticketManage'});
                        } catch (e) {
                            console.log(e);
                            this.isSubmit = false;
                        }
                    }
                });
            },


            // 展示的状态
            getStatus() {
                switch (this.formData.couponsStatus) {
                    case -1:
                        return '未上架';
                    case 1:
                        return '上架';
                    case 2:
                        return '过期';
                    case 3:
                        return '已领完';
                    case 4:
                        return '下架';
                }
            }
        }
    };
</script>
<style>
    /*.el-input {*/
    /*width: 218px;*/
    /*}*/
    /*.type {*/
    /*margin-top: 0;*/
    /*}*/
    /*.type .el-input {*/
    /*width: 80px;*/
    /*}*/
</style>

